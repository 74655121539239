import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isAuthenticated } from '../store';

// Router that checks if user is logged-in
// If not, redirect to "/login"
const PrivateRoute = ({ render, ...rest }) => (
  <Route
    {...rest}
    render={() => (isAuthenticated() ? render() : (
      <Redirect
        to={{
          pathname: '/login',
        }}
      />
    ))}
  />
);

PrivateRoute.propTypes = {
  render: PropTypes.func.isRequired,
};

export default PrivateRoute;
