import { Store } from 'pullstate';
import ApiClient from '../utils/ApiClient';

const defaultState = {
  user: null,
  sidebarShow: true,
};

const appStore = new Store(defaultState);

export const updateAppStore = (values) => {
  // console.debug('UPDATE STORE', values);
  const updatedValues = (s) => {
    Object.keys(values).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      s[key] = values[key];
    });
  };
  appStore.update(updatedValues);
};

export const checkAuthentication = async () => {
  try {
    const user = await ApiClient.checkAuthentication();
    updateAppStore({ user });
  } catch (e) {
    updateAppStore({ user: null });
  }
};

export const isAuthenticated = () => {
  const { user } = appStore.getRawState();
  return (user !== null);
};

export const logout = async () => {
  await ApiClient.logout();
  window.location = '/login';
};

export const resetStore = () => {
  updateAppStore({ ...defaultState });
};

export default appStore;
